<template>
  <v-col>
    <v-container fluid>
      <v-col class="mx-auto text-center">
        <img src="/img/logo.png" width="100px" />
        <h1>Conditions-Circulation</h1>
      </v-col>
      <v-card width="50vw" class="mx-auto">
        <v-card-title>Se connecter</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login()">
            <v-text-field
              v-model="form.identifier"
              required
              placeholder="Entrez votre adresse email"
            />
            <v-text-field
              v-model="form.password"
              type="password"
              required
              placeholder="Entrez votre adresse email"
            />
            <v-btn type="submit" block :disabled="loading">
              <template v-if="loading"
                ><v-progress-circular size="20" indeterminate
              /></template>
              Connexion
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
      <v-snackbar top v-model="error" color="error"
        >Mauvais login ou mot de passe</v-snackbar
      >
    </v-container>
  </v-col>
</template>

<script>
import api from "@/api";
export default {
  data: () => ({
    loading: false,
    error: false,
    form: {
      identifier: null,
      password: null,
    },
  }),
  methods: {
    async login() {
      this.loading = true;
      try {
        let login = await api.login(this.form);
        this.$store.dispatch("setUser", login.user);
        this.$store.dispatch("setJwt", login.jwt);

        this.$router.push("/");
      } catch (err) {
        this.error = true;
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>

<style>
</style>